<template>
  <div class="hours-search-container">
    <el-date-picker
      class="search_item"
      type="dates"
      v-model="searchquery.dateList"
      placeholder="选择一个或多个日期"
      placement="bottom-start"
      ref="datePick"
      value-format="yyyy-MM-dd"
      @change="() => {
        isLoading = false
        handleAutoSearch()
      }"
      :clearable="false"
      :picker-options="pickerOptions"
    >
    </el-date-picker>
    <m-select-more
      class="search_item"
      v-model="searchquery.userIdList"
      :allData="adminUserList"
      :searchById="true"
      :hasIcon="false"
      :showId="false"
      label="账户"
      :showSelectedPart="true"
      :hasSearch="true"
      width="450px"
      :canSelectAll="true"
      @change="handleChangeUser"
      v-if="role === 'SUPER_ADMIN'"
    />
    <m-select-more
      class="search_item"
      v-model="searchquery.publisherIdList"
      :allData="supplierList"
      :searchById="true"
      :hasIcon="true"
      :showId="true"
      width="450px"
      label="供应商"
      @change="changeSupplier"
    />
    <m-select-more
      class="search_item"
      v-model="searchquery.placeIdList"
      :allData="placeList"
      :searchById="true"
      :hasIcon="true"
      :showId="true"
      width="450px"
      label="广告位"
      @change="changePlaceIds"
    />
    <m-select-more
      class="search_item"
      v-model="searchquery.dspPlaceIdList"
      :allData="dspList"
      :searchById="true"
      :hasIcon="false"
      :showId="true"
      label="广告源"
      :showSelectedPart="true"
      width="450px"
      :canSelectAll="true"
      @change="handleAutoSearch"
    />
    <m-select-more
      class="search_item"
      v-model="searchquery.positionIdList"
      :allData="positionList"
      :searchById="false"
      :hasIcon="false"
      :showId="false"
      :hasSearch="false"
      label="广告样式"
      :showSelectedPart="true"
      width="450px"
      :canSelectAll="true"
      @change="handleAutoSearch"
    />
    <m-select-more
      class="search_item"
      v-model="searchquery.platIdList"
      :allData="platList"
      :searchById="false"
      :hasIcon="false"
      :showId="false"
      label="广告平台"
      :showSelectedPart="true"
      width="450px"
      :canSelectAll="true"
      @change="changePlat"
    />
    <m-select-more
      class="search_item"
      v-model="searchquery.osIdList"
      :allData="[
        { name: '安卓', id: '1' },
        { name: 'IOS', id: '0' }
      ]"
      :searchById="false"
      :hasIcon="false"
      :hasSearch="false"
      :showId="false"
      label="操作系统"
      :showSelectedPart="false"
      width="240px"
      :canSelectAll="true"
      @change="handleAutoSearch"
    />
    <m-select-tree class="search_item" :options="areaTreeList" v-model="searchquery.countryCodeList" label="地区" @change="handleAutoSearch"/>
    <m-select-more
      class="search_item"
      v-model="searchquery.bundleList"
      :allData="trafficBundleList"
      :searchById="true"
      :hasIcon="true"
      :showId="true"
      label="应用包名"
      :hasSearch="true"
      :showSelectedPart="true"
      width="450px"
      :canSelectAll="true"
      @change="handleAutoSearch"
    />
    <m-select-more
      class="search_item"
      v-model="searchquery.taskList"
      :allData="dspTaskList"
      :searchById="true"
      :hasIcon="false"
      :showId="false"
      label="任务"
      :showSelectedPart="true"
      width="450px"
      :canSelectAll="true"
      @change="handleAutoSearch"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import mixDate from '@/mixins/initDate'
import mixGetList from '@/mixins/getListForManage'
import moment from 'moment'
import { toDate } from '@/utils/toTimer'
import { getDspList } from '@/apiForManage/common'
import { partnerIdList } from '@/data/dataList'
export default {
  mixins: [mixDate, mixGetList],
  data () {
    return {
      searchquery: {
        dateList: [],
        userIdList: [],
        publisherIdList: [],
        placeIdList: [],
        positionIdList: [],
        platIdList: [],
        osIdList: [],
        dspPlaceIdList: [],
        countryCodeList: [],
        bundleList: [],
        taskList: [],
        page: 1,
        limit: 10
      },
      pickerOptions: {
        disabledDate (time) {
          const date = '2021-08-31'
          // 禁选今天以后 或  2021年9月之前的 time < moment(date)
          return time.getTime() > Date.now() || time < moment(date)
        },
        onPick () {
        }
      },
      isLoading: false,
      dspList: [],
      partnerIdList
    }
  },
  watch: {
    'searchquery.dateList': {
      handler (val) {
        if (val.length > 5) {
          this.searchquery.dateList.splice(0, 1)
        }
      }
    }
  },
  computed: {
    ...mapState({
      role: (state) => state.user.roles[0],
      title: (state) => state.autoweb.title
    })
  },
  async created () {
    // 初始化时间
    // 今天
    const today = toDate(Date.now())
    // 昨天
    const yesterday = toDate(Date.now() - 24 * 60 * 60 * 1000)
    this.searchquery.dateList = [yesterday, today]
    this.role === 'SUPER_ADMIN' && await this.getAdminUserList()
    await this.getSupplierList(this.searchquery.userIdList)
    this.getPlaceList()
    this.getPositionList()
    this.getPlatListByuser()
    this.getDspList()
    this.getAreaTreeList()
    this.getTrafficBundleList()
    this.getDspTaskList()
    this.dealQuery()
    // 进入时搜索
    this.$emit('handleAutoSearch', this.searchquery)
  },
  methods: {
    async handleChangeUser () {
      await this.getSupplierList(this.searchquery.userIdList)
      this.changeSupplier()
    },
    dealQuery () {
      const { query = {} } = this.$route
      const { newDate, oldDate } = query
      if (oldDate && newDate) {
        this.searchquery.dateList = [oldDate, newDate]
      }
    },
    async changePlat (ids) {
      await this.getDspList(ids)
      this.handleAutoSearch()
    },
    async getDspList (platIds = []) {
      const { data = [] } = await getDspList({
        platAccountIdList: [],
        platIdList: platIds
      })
      data.forEach((item) => {
        item.searchId = item.platPlaceId
      })
      this.dspList = data
    },
    filterOption (input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    onPick (e, f, g) {
    },
    // 供应商改变查广告位
    async changeSupplier (ids) {
      this.getPlaceList(ids)
      this.changePlaceIds(this.searchquery.placeIdList)
    },
    // 广告位改变查流量分组
    async changePlaceIds (ids) {
      this.handleAutoSearch()
    },
    handleAutoSearch () {
      if (this.isLoading) return
      setTimeout(() => {
        this.isLoading = false
      }, 1000)
      this.searchquery.dateList.sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
      this.$emit('handleAutoSearch', this.searchquery)
      this.isLoading = true
    }
  }
}
</script>

<style lang="less">
.hours-search-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: calc(@mediumMargin - 7px);
  .search_item {
    margin-right: @compMargin;
    margin-bottom: @compMargin;
    width: 220px;
    display: inline-block;
  }
}
</style>
